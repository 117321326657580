import React, { useState, useEffect } from "react";
import Dayjs from "dayjs";
import {formatDate, getEstimatedHours ,calculateControlOpenTime, 
  calculateControlCloseTime, getLocalStorageValue, getCurrentDimension, 
  getTimeByFromAndToDate} from './Utils';

interface FormComponentProps {}

interface FormData {
  startDate: Date;
  averageSpeed: number;
  startPlace: string;

  control1: number;
  place1: string;
  break1: number;
  arrival1: Dayjs.Dayjs;

  control2: number;
  place2: string;
  break2: number;
  arrival2: Dayjs.Dayjs;

  control3: number;
  place3: string;
  break3: number;
  arrival3: Dayjs.Dayjs;

  control4: number;
  place4: string;
  break4: number;
  arrival4: Dayjs.Dayjs;

  control5: number;
  place5: string;
  break5: number;
  arrival5: Dayjs.Dayjs;

  control6: number;
  place6: string;
  break6: number;
  arrival6: Dayjs.Dayjs;

  control7: number;
  place7: string;
  break7: number;
  arrival7: Dayjs.Dayjs;

  control8: number;
  place8: string;
  break8: number;
  arrival8: Dayjs.Dayjs;

  control9: number;
  place9: string;
  break9: number;
  arrival9: Dayjs.Dayjs;

  control10: number;
  place10: string;
  break10: number;
  arrival10: Dayjs.Dayjs;

  control11: number;
  place11: string;
  arrival11: Dayjs.Dayjs;

  [key: string] : any
}

const FormComponent: React.FC<FormComponentProps> = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  var startDate = Dayjs("2022-07-01T05:00:00").toDate();

  const [formData, setFormData] = useState<FormData>({
    startDate: getLocalStorageValue('startDate') ?? startDate,
    averageSpeed: getLocalStorageValue('averageSpeed') ?? 20,
    startPlace: getLocalStorageValue('startPlace') ?? "Helsinki",

    control1: getLocalStorageValue('control1') ?? 153,
    place1: getLocalStorageValue('place1') ?? "Vääksi",
    break1: getLocalStorageValue('break1') ?? 1,
    arrival1: getLocalStorageValue('arrival1') ?? Dayjs("2022-07-01T12:39:00"),

    control2: getLocalStorageValue('control2') ?? 216,
    place2: getLocalStorageValue('place2') ?? "Hartola",
    break2: getLocalStorageValue('break2') ?? 0.5,
    arrival2: getLocalStorageValue('arrival2') ?? Dayjs("2022-07-01T16:48:00"),

    control3: getLocalStorageValue('control3') ?? 322,
    place3: getLocalStorageValue('place3') ?? "Pitkäjärvi",
    break3: getLocalStorageValue('break3') ?? 4.5,
    arrival3: getLocalStorageValue('arrival3') ?? Dayjs("2022-07-01T22:36:00"),

    control4: getLocalStorageValue('control4') ?? 435,
    place4: getLocalStorageValue('place4') ?? "Ruokolahti",
    break4: getLocalStorageValue('break4') ?? 0.5,
    arrival4: getLocalStorageValue('arrival4') ?? Dayjs("2022-07-02T08:45:00"),

    control5: getLocalStorageValue('control5') ?? 539,
    place5: getLocalStorageValue('place5') ?? "Savonlinna",
    break5: getLocalStorageValue('break5') ?? 0.5,
    arrival5: getLocalStorageValue('arrival5') ?? Dayjs("2022-07-02T14:27:00"),

    control6: getLocalStorageValue('control6') ?? 613,
    place6: getLocalStorageValue('place6') ?? "Parikkala",
    break6: getLocalStorageValue('break6') ?? 0.5,
    arrival6: getLocalStorageValue('arrival6') ?? Dayjs("2022-07-02T18:39:00"),

    control7: getLocalStorageValue('control7') ?? 679,
    place7: getLocalStorageValue('place7') ?? "Imatra",
    break7: getLocalStorageValue('break7') ?? 8,
    arrival7: getLocalStorageValue('arrival7') ?? Dayjs("2022-07-02T22:27:00"),

    control8: getLocalStorageValue('control8') ?? 800,
    place8: getLocalStorageValue('place8') ?? "Tuohikotti",
    break8: getLocalStorageValue('break8') ?? 1,
    arrival8: getLocalStorageValue('arrival8') ?? Dayjs("2022-07-03T12:30:00"),

    control9: getLocalStorageValue('control9') ?? 866,
    place9: getLocalStorageValue('place9') ?? "Kausala",
    break9: getLocalStorageValue('break9') ?? 0.25,
    arrival9: getLocalStorageValue('arrival9') ?? Dayjs("2022-07-03T16:48:00"),

    control10: getLocalStorageValue('control10') ?? 951,
    place10: getLocalStorageValue('place10') ?? "Kuninkaanportti",
    break10: getLocalStorageValue('break10') ?? 0.45,
    arrival10: getLocalStorageValue('arrival10') ?? Dayjs("2022-07-03T21:18:00"),

    control11: getLocalStorageValue('control11') ?? 1005,
    place11: getLocalStorageValue('place11') ?? "Helsinki",
    arrival11: getLocalStorageValue('arrival11') ?? Dayjs("2022-07-04T00:27:00"),
  });

  
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])


  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    let parsedValue: any;

    if (name === "averageSpeed" || name.indexOf("control") > -1 || name.indexOf("break") > -1) {
      parsedValue = Number(value);
    } else if (name === "startDate") {
      parsedValue = Dayjs(value).format("YYYY-MM-DDTHH:mm");
    } else {
      parsedValue = value;
    }

    var updated = { ...formData, [name]: parsedValue };
    //Init arrival again if control disance set to 0
    if(updated[`control${e.target.id}`] === 0)
      updated[`arrival${e.target.id}`] = Dayjs();

    updated.arrival1 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control1, updated.averageSpeed),
      "hours"
    );
    updated.arrival2 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control2, updated.averageSpeed) + 
      updated.break1,
      "hours"
    );
    updated.arrival3 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control3, updated.averageSpeed) +
        updated.break1 +
        updated.break2,
      "hours"
    );
    updated.arrival4 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control4, updated.averageSpeed) +
        updated.break1 +
        updated.break2 +
        updated.break3,
      "hours"
    );
    updated.arrival5 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control5, updated.averageSpeed) +
        updated.break1 +
        updated.break2 +
        updated.break3 +
        updated.break4,
      "hours"
    );
    updated.arrival6 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control6, updated.averageSpeed) +
        updated.break1 +
        updated.break2 +
        updated.break3 +
        updated.break4 +
        updated.break5,
      "hours"
    );
    updated.arrival7 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control7, updated.averageSpeed) +
        updated.break1 +
        updated.break2 +
        updated.break3 +
        updated.break4 +
        updated.break5 +
        updated.break6,
      "hours"
    );
    updated.arrival8 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control8, updated.averageSpeed) +
        updated.break1 +
        updated.break2 +
        updated.break3 +
        updated.break4 +
        updated.break5 +
        updated.break6 +
        updated.break7,
      "hours"
    );
    updated.arrival9 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control9, updated.averageSpeed) +
        updated.break1 +
        updated.break2 +
        updated.break3 +
        updated.break4 +
        updated.break5 +
        updated.break6 +
        updated.break7 +
        updated.break8,
      "hours"
    );
    updated.arrival10 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control10, updated.averageSpeed) +
        updated.break1 +
        updated.break2 +
        updated.break3 +
        updated.break4 +
        updated.break5 +
        updated.break6 +
        updated.break7 +
        updated.break8 +
        updated.break9,
      "hours"
    );
    updated.arrival11 = Dayjs(updated.startDate).add(
      getEstimatedHours(updated.control11, updated.averageSpeed) +
        updated.break1 +
        updated.break2 +
        updated.break3 +
        updated.break4 +
        updated.break5 +
        updated.break6 +
        updated.break7 +
        updated.break8 +
        updated.break9 +
        updated.break10,
      "hours"
    );

    localStorage.setItem('formData', JSON.stringify(updated));
    setFormData(updated);
  };


  const reset = (e: any) => {
    const updated = {
      ...formData,
      startPlace: "",
      control1: 0,
      place1: "",
      break1: 0,
      arrival1: Dayjs(),
      control2: 0,
      place2: "",
      break2: 0,
      arrival2: Dayjs(),
      control3: 0,
      place3: "",
      break3: 0,
      arrival3: Dayjs(),
      control4: 0,
      place4: "",
      break4: 0,
      arrival4: Dayjs(),
      control5: 0,
      place5: "",
      break5: 0,
      arrival5: Dayjs(),
      control6: 0,
      place6: "",
      break6: 0,
      arrival6: Dayjs(),
      control7: 0,
      place7: "",
      break7: 0,
      arrival7: Dayjs(),
      control8: 0,
      place8: "",
      break8: 0,
      arrival8: Dayjs(),
      control9: 0,
      place9: "",
      break9: 0,
      arrival9: Dayjs(),
      control10: 0,
      place10: "",
      break10: 0,
      arrival10: Dayjs(),
      control11: 0,
      place11: "",
      arrival11: Dayjs(),
    };
  
    localStorage.setItem('formData', JSON.stringify(updated));
    setFormData(updated);
  };


  function GetWideControlTable()
  {
    return (            
      <table className="control-table">
      <tbody>
        <tr>
          <td className="form-group">
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="datetime-local"
              id="startDate"
              name="startDate"
              value={Dayjs(formData.startDate).format(
                "YYYY-MM-DDTHH:mm"
              )}
              onChange={handleInputChange}
              required
            />
          </td>
          <td className="form-group">
            <label htmlFor="averageSpeed">Average Speed:</label>
            <input
              type="number"
              id="averageSpeed"
              name="averageSpeed"
              value={formData.averageSpeed}
              onChange={handleInputChange}
              required
            />
          </td>
          <td className="form-group">
            <label htmlFor="averageSpeed">Reset:</label>
            <button onClick={reset}>Clear Card</button>
          </td>
        </tr>
      </tbody>
    </table>
    )
  }

  function GetNarrowControlTable()
  {
    return (            
      <table className="control-table">
      <tbody>
        <tr>
          <td className="form-group">
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="datetime-local"
              id="startDate"
              name="startDate"
              value={Dayjs(formData.startDate).format(
                "YYYY-MM-DDTHH:mm"
              )}
              onChange={handleInputChange}
              required
            />
          </td>
        </tr>
        <tr>
          <td className="form-group">
            <label htmlFor="averageSpeed">Average Speed:</label>
            <input
              type="number"
              id="averageSpeed"
              name="averageSpeed"
              value={formData.averageSpeed}
              onChange={handleInputChange}
              required
            />
          </td>
        </tr>
        <tr>
          <td className="form-group">
            <label htmlFor="averageSpeed">Reset:</label>
            <button onClick={reset}>Clear Card</button>
          </td>
        </tr>
      </tbody>
    </table>
    )
  }

  function constructHeaderTD()
  {
    return(
      <React.Fragment>
        <td className="brevet-card-th">
          Distance
          <br />
          Location
        </td>
        <td className="brevet-card-th">
          Signature or stamp
          <br />
          Arrival time
        </td>
      </React.Fragment>
    )
  }

  function constructStartTD(formData : FormData, handleInputChange : React.ChangeEventHandler<HTMLInputElement>) {
    return (
      <React.Fragment>
        <td className="brevet-card-td">
          <b>Start: 0</b>
          <br />
          Place / Address
          <br />
          <input
            className="brevet-card-input-place"
            type="text"
            id="startPlace"
            name="startPlace"
            value={formData.startPlace}
            onChange={handleInputChange}
            required
          />
          <br />
        </td>
        
        <td className="brevet-card-td">
          <b>Arrival time</b>
          <br />
          {formatDate(formData.startDate)}
        </td>
      </React.Fragment>
    );
  }  

  function constructControlTD(controlNumber : string, formData : FormData, handleInputChange : React.ChangeEventHandler<HTMLInputElement>) {
    return (
      <React.Fragment>
        <td className="brevet-card-td">
          <b>{controlNumber} Km: </b>
          <input
            className="brevet-card-input-km"
            type="number"
            id={`${controlNumber}`}
            name={`control${controlNumber}`}
            value={formData[`control${controlNumber}`]}
            onChange={handleInputChange}
            required
          />
          <br />
          Place / Address
          <br />
          <input
            className="brevet-card-input-place"
            type="text"
            id={`place${controlNumber}`}
            name={`place${controlNumber}`}
            value={formData[`place${controlNumber}`]}
            onChange={handleInputChange}
            required
          />
          <br />
          {
            controlNumber !== "11" && (
              <React.Fragment>
                Break (h):
                <input
                  className="brevet-card-input-km"
                  type="number"
                  id={`break${controlNumber}`}
                  name={`break${controlNumber}`}
                  value={formData[`break${controlNumber}`]}
                  onChange={handleInputChange}
                  required
                />
                <br />
              </React.Fragment>)
          }
          <br />
        </td>

        <td className="brevet-card-td">
          <b>Arrival time</b> <br />
            {formData[`control${controlNumber}`] === 0
              ? null
              : formatDate(formData[`arrival${controlNumber}`]) + " "}

            <br/>
            <b>Duration</b> (hh:mm)<br />
            {formData[`control${controlNumber}`] === 0
              ? null
              : getTimeByFromAndToDate(Dayjs(formData.startDate), Dayjs(formData[`arrival${controlNumber}`]))}
        </td>
      </React.Fragment>
    );
  }  

  function GetWideBrevetTable()
  {
    return(
      <table className="brevet-card-table background-table">
      <tbody>
        <tr>
          {constructHeaderTD()}
          {constructHeaderTD()}
          {constructHeaderTD()}
        </tr>

        <tr>
          {constructStartTD(formData, handleInputChange)}
          {constructControlTD("4", formData, handleInputChange)}
          {constructControlTD("8", formData, handleInputChange)}
        </tr>

        <tr>
          {constructControlTD("1", formData, handleInputChange)}
          {constructControlTD("5", formData, handleInputChange)}
          {constructControlTD("9", formData, handleInputChange)}
        </tr>

        <tr>
          {constructControlTD("2", formData, handleInputChange)}
          {constructControlTD("6", formData, handleInputChange)}
          {constructControlTD("10", formData, handleInputChange)}
        </tr>

        <tr>
          {constructControlTD("3", formData, handleInputChange)}
          {constructControlTD("7", formData, handleInputChange)}
          {constructControlTD("11", formData, handleInputChange)}
        </tr>
      </tbody>
    </table>
    )
  }

  function GetNarrowBrevetTable()
  {
    return(
      
      <table className="brevet-card-table background-table">
      <tbody>
        <tr>
          {constructHeaderTD()}
        </tr>
        <tr>
          {constructStartTD(formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("1", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("2", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("3", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("4", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("5", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("6", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("7", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("8", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("9", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("10", formData, handleInputChange)}
        </tr>
        <tr>
          {constructControlTD("11", formData, handleInputChange)}
        </tr>
      </tbody>
    </table>
    )
  }

  return (
    <div className="content-box">
      { screenSize.width > 1080 ? GetWideControlTable() : GetNarrowControlTable() }
      <br />
      { screenSize.width > 1080 ? GetWideBrevetTable() : GetNarrowBrevetTable() }
    </div>
  );
};

export default FormComponent;
