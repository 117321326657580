import React from 'react';
import Icon from './Icon';
import BrevetCalculator from './BrevetCalculator';

const App: React.FC = () => {

  return (

    <div className="long-distance-cycling-page">
      <div className="top-area">
        <h1 className="page-title">Brevet Calculator</h1>
        <p><i></i>Explore the world on two wheels</p>
        <Icon />
      </div>

      <div className="app">
        <BrevetCalculator />
      </div>
      <div className="table-info">
        <b>Control open and close times are calculated according to ACP Brevet Control Times</b><br/>
        <i>(https://rusa.org/pages/acp-brevet-control-times-calculator)</i>
        <p>
        <b>13:30 for 200 KM (+10 min), 20:00 for 300 KM, 27:00 for 400 KM (+20 min), 40:00 for 600 KM, and 75:00 for 1000 KM</b><br/>
        <i>(https://rusa.org/pages/rulesForRiders)</i>
        </p>
        <p><span className="warning">⚠</span> <b>Please ensure control open and close times from your event organizer since minor differences may occur!</b></p>


        <p><b>Latest updates</b><br/>
        7.6.2023 Brevet calculator initial release<br/>
        8.6.2023 Adaptivity for narrower displays added<br/>
        8.6.2023 Preserve brevet plan in browser storage added<br/>
        12.6.2023 Added <span className="warning">⚠</span> after arrival if later than control close<br/>
        13.6.2023 Brevet deadlines added from rusa.org<br/>
        15.6.2023 Calculation of duration added<br/>
        23.7.2024 Control open-close removed since rules changed<br/>
        </p>
      </div>
      <div className="bottom-area">
        <i>Powered by Kalajärven Näädät ACP: FI031424</i><br/><br/>
        <img src="kn.svg" width={65} />      
      </div>
    </div>
  );
};

export default App;