import React from "react";

function Icon() {
  return (
    <div style={{ transform: 'scale(0.14)', height: 0, padding: 20}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="756"
      height="462"
      viewBox="260 530 600 100">
      <rect width="100%" height="100%" fill="transparent"></rect>
      <rect
        width="756"
        height="462"
        x="-540"
        y="-540"
        fill="#FFF"
        rx="0"
        ry="0"
        transform="translate(540 540)"
        vectorEffect="non-scaling-stroke"
        visibility="hidden"
      ></rect>
      <path
        d="M-4.924-1.248c-1.71 4.025 9.382 2.305 10.017.812"
        transform="matrix(7.03 0 0 7.03 504.85 414.99)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="24"
        d="M0 42.875c-23.642 0-42.875-19.233-42.875-42.875S-23.642-42.875 0-42.875 42.875-23.642 42.875 0 23.642 42.875 0 42.875zm0-83.75c-22.539 0-40.875 18.336-40.875 40.875 0 22.538 18.336 40.875 40.875 40.875 22.538 0 40.875-18.337 40.875-40.875 0-22.539-18.337-40.875-40.875-40.875z"
        transform="matrix(1.93 0 0 1.93 429.26 578.79)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="24"
        d="M0 42.875c-23.642 0-42.875-19.233-42.875-42.875S-23.642-42.875 0-42.875 42.875-23.642 42.875 0 23.642 42.875 0 42.875zm0-83.75c-22.539 0-40.875 18.336-40.875 40.875 0 22.538 18.336 40.875 40.875 40.875 22.538 0 40.875-18.337 40.875-40.875 0-22.539-18.337-40.875-40.875-40.875z"
        transform="matrix(1.93 0 0 1.93 682.25 581.32)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="0"
        d="M44.75 0c0 6.213-5.236 11.25-11.696 11.25h-66.108c-6.46 0-11.696-5.037-11.696-11.25h0c0-6.213 5.236-11.25 11.696-11.25h66.107C39.514-11.25 44.75-6.213 44.75 0h0z"
        transform="matrix(-1.48 0 0 .38 495.62 576.81)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="0"
        d="M44.75 0c0 6.213-5.236 11.25-11.696 11.25h-66.108c-6.46 0-11.696-5.037-11.696-11.25h0c0-6.213 5.236-11.25 11.696-11.25h66.107C39.514-11.25 44.75-6.213 44.75 0h0z"
        transform="matrix(-.81 1.53 .38 .2 594.88 509.48)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="0"
        d="M44.75 0c0 6.213-5.236 11.25-11.696 11.25h-66.108c-6.46 0-11.696-5.037-11.696-11.25h0c0-6.213 5.236-11.25 11.696-11.25h66.107C39.514-11.25 44.75-6.213 44.75 0h0z"
        transform="matrix(.75 1.54 .27 -.13 524.85 508.27)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="0"
        d="M44.75 0c0 6.213-5.236 11.25-11.696 11.25h-66.108c-6.46 0-11.696-5.037-11.696-11.25h0c0-6.213 5.236-11.25 11.696-11.25h66.107C39.514-11.25 44.75-6.213 44.75 0h0z"
        transform="matrix(-.67 1.56 .3 .13 460.58 508.19)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="0"
        d="M44.75 0c0 6.213-5.236 11.25-11.696 11.25h-66.108c-6.46 0-11.696-5.037-11.696-11.25h0c0-6.213 5.236-11.25 11.696-11.25h66.107C39.514-11.25 44.75-6.213 44.75 0h0z"
        transform="matrix(1.61 0 0 -.37 560.37 439.74)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="0"
        d="M44.75 0c0 6.213-5.236 11.25-11.696 11.25h-66.108c-6.46 0-11.696-5.037-11.696-11.25h0c0-6.213 5.236-11.25 11.696-11.25h66.107C39.514-11.25 44.75-6.213 44.75 0h0z"
        transform="matrix(.56 1.6 .32 -.11 656.2 510.26)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#FFF"
        strokeWidth="0"
        d="M44.75 0c0 6.213-5.236 11.25-11.696 11.25h-66.108c-6.46 0-11.696-5.037-11.696-11.25h0c0-6.213 5.236-11.25 11.696-11.25h66.107C39.514-11.25 44.75-6.213 44.75 0h0z"
        transform="matrix(.57 .14 .08 -.33 627.87 426.91)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </svg>
  </div>
  );
}

export default Icon;