import Dayjs from "dayjs";

export function getEstimatedHours(distance: number, avgSpeed: number) {
  return distance / avgSpeed;
}

export function formatDate(val: Dayjs.Dayjs | Date) {
  return Dayjs(val).format("DD/MM/YYYY HH:mm");
  //return Dayjs(val).format("YYYY-MM-DDTHH:mm:ss");
}

export const roundToNearestMinute = (date : Dayjs.Dayjs) => {
  return Dayjs(date).startOf('minute');
};

  //https://rusa.org/pages/acp-brevet-control-times-calculator
export function calculateControlOpenTime(distance: number, startDate: Date) {
  let hours: number;

  if (distance >= 0 && distance <= 200) {
    hours = distance / 34;
  } else if (distance > 200 && distance <= 400) {
    hours = 200 / 34 + (distance - 200) / 32;
  } else if (distance > 400 && distance <= 600) {
    hours = 200 / 34 + 200 / 32 + (distance - 400) / 30;
  } else if (distance > 600 && distance <= 1000) {
    hours = 200 / 34 + 200 / 32 + 200 / 30 + (distance - 600) / 28;
  } else {
    hours = 200 / 34 + 200 / 32 + 200 / 30 + 400 / 28 + (distance - 1000) / 26;
  }

  let openTime = Dayjs(startDate).add(hours, "hours");
  return roundToNearestMinute(openTime);
}

export function calculateControlCloseTime(distance: number, startDate: Date) {
  let hours: number;

  if (distance <= 60) {
    hours = distance / 20 + 1; //French oddity
  } else if (distance >= 0 && distance <= 200) {
    hours = distance / 15;
  } else if (distance > 200 && distance <= 400) {
    hours = 200 / 15 + (distance - 200) / 15;
  } else if (distance > 400 && distance <= 600) {
    hours = 200 / 15 + 200 / 15 + (distance - 400) / 15;
  } else if (distance > 600 && distance <= 1000) {
    hours = 200 / 15 + 200 / 15 + 200 / 15 + (distance - 600) / 11.428;
  } else {
    hours = 200 / 15 + 200 / 15 + 200 / 15 + 400 / 11.428 + (distance - 1000) / 13.333;
  }

  let closeTime = Dayjs(startDate).add(hours, "hours");
  return roundToNearestMinute(closeTime);
}

export function getLocalStorageValue(key : string)
{
  return JSON.parse(localStorage.getItem('formData') ?? "{}")[key];
}

export function getTimeByFromAndToDate(fromDate : Dayjs.Dayjs, toDate : Dayjs.Dayjs) {
  const duration = toDate.diff(fromDate, 'minute');
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  const timeDiff = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return timeDiff;
}

export function getCurrentDimension(){
  return {
      width: window.innerWidth,
      height: window.innerHeight
  }
}
